import React, { useEffect } from 'react';
import './Home.scss';
import gsap from'gsap';
import  CustomEase  from "gsap/CustomEase";
// import video_home from '../../Assets/video1.mp4';
// import video_home_webm from '../../Assets/video1.webm';
import image_home from '../../Assets/home_back.png';
import AnimatedLink from '../AnimatedLink/AnimatedLink';
import AnimatedText from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom';
import { AppDataContext } from '../../App';
export default function Home(){
    var black_bottom = React.useRef(null);
    var black_top    = React.useRef(null);
    const {firstLoad , Setfl}= React.useContext(AppDataContext)
    useEffect(
        ()=>{
            if(firstLoad){
            loading();   
            }else{
            video_play();
            }
            return()=>{
                clearInterval(it1);
                clearInterval(it2);
                if(firstLoad){
                    Setfl(false);
                }
            }
        }
    ,[]);
    var number_span= React.useRef(null);
    var prog = React.useRef(null);
    var loading_ref = React.useRef(null);
    let video_loaded =false;
    let percentage = 0;
    let it1 = null;
    let it2 = null;
    const loading=()=>{
        gsap.set(loading_ref.current,{opacity:1});
        it1 = setInterval(()=>{
         percentage++;
         number_span.current.innerText=percentage;
         prog.current.style.width=percentage+"%";
         if(video_loaded){
             fast_loading();
             clearInterval(it1);
         }
         if(percentage===100){
          clearInterval(it1);
          gsap.to(loading_ref.current,{duration:.5 , x:100,opacity:0,zIndex:-1})
          video_play();
         }
        },60);
    }
    const fast_loading=()=>{
        it2 = setInterval(()=>{
            percentage++;
            number_span.current.innerText=percentage;
            prog.current.style.width=percentage+"%";
            if(percentage===100){
             clearInterval(it2);
             gsap.to(loading_ref.current,{duration:.5 , x:100,opacity:0,zIndex:-1})
             video_play();
            }
           },20);
    }
    const loading_end=()=>{
        gsap.registerPlugin(CustomEase);
        gsap.to('.home_text > div',{duration:1,opacity:1,x:0,stagger:.5})
    }
    const video_play=(e)=>{
        // e.target.play();
        loading_end();
        gsap.to('.blackT',{duration:3 , delay:.5 ,y:'-120%'  ,ease:   CustomEase.create("custom", "M0,0 C0,0 0.476,-0.016 0.512,0.132 0.518,0.16 0.536,0.24 0.548,0.262 0.606,0.376 0.625,0.387 0.634,0.4 0.754,0.564 0.68,0.456 0.786,0.618 0.857,0.726 0.782,0.618 0.862,0.712 0.874,0.726 0.92,0.733 0.926,0.754 1,1.012 0.92,0.896 1,1 1.062,1.081 1,1 1,1 ")})
        gsap.to('.blackB',{duration:3 ,delay:.5 , y:'120%', ease:  CustomEase.create("custom", "M0,0 C0,0 0.476,-0.016 0.512,0.132 0.518,0.16 0.536,0.24 0.548,0.262 0.606,0.376 0.625,0.387 0.634,0.4 0.754,0.564 0.68,0.456 0.786,0.618 0.857,0.726 0.782,0.618 0.862,0.712 0.874,0.726 0.92,0.733 0.926,0.754 1,1.012 0.92,0.896 1,1 1.062,1.081 1,1 1,1 ")});
    }

    return(
        <>
        {
            firstLoad ? 
            <div className="loading" ref={loading_ref}>
            <span className="prog_number" ref={number_span}>0</span> 
            <div className="prog" ref={prog}></div>
            </div> :<></>
        }
        <div className="home">
            <div className="image_loader">
                 <div className="blackT" ref={black_top}></div>
                 <div className="blackB" id="home_blackB" ref={black_bottom}></div>
                 <video preload="auto"loop playsInline={true}
                        poster={image_home} 
                        muted
                        autoPlay 
                        onError={(e)=>{console.log(e)}}
                        onLoadedMetadata={()=>{video_loaded = true}}
                        className="video">  
                    {/* <source src="https://spot.film/videos/V2.webm" type="video/webm"></source> */}
                    <source src="https://dl.dropboxusercontent.com/s/hqonnfczty5eatw/Web%20interface%20show%20reel%20-%20with%20pixels%20-%20Sifr.mp4/" type="video/mp4"></source>
                 </video>              
{/* <iframe src="https://www.youtube.com/embed/XDCXT7C7lnk?autoplay=1&mute=1&loop=1&playlist=XDCXT7C7lnk&controls=0" className="vimeo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
{/* <iframe src="https://player.vimeo.com/video/448328532?background=1&autoplay=1&loop=1&controls=0&muted=1" className="vimeo" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> */}
                 {/* <img src={video_home} className="video"></img> */}
            </div>
            <div className="home_text">
             <div className="home_title" style={{transform:'translateX(-100px)' , opacity:0}}>
                 <AnimatedText Text={<>Sifr stands not for<br/> the closing of a ring, it is rather a gateway.</>} animate={true}></AnimatedText>
             </div>
             <div className="home_selected_works" style={{transform:'translateX(-100px)' , opacity:0}}>
                <Link to='/works' className="info">
                    <AnimatedLink text="Selected works"></AnimatedLink>
                </Link>
             </div>
            </div>
        </div>
        </>

    )
}