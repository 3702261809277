import React from 'react';

import pic_1 from '../Assets/worksPage/works_pic/1.jpg';
import pic_2 from '../Assets/worksPage/works_pic/2.jpg';
import pic_3 from '../Assets/worksPage/works_pic/4.jpg';
import pic_4 from '../Assets/worksPage/works_pic/5.jpg';
import pic_5 from '../Assets/worksPage/works_pic/6.jpg';
import pic_6 from '../Assets/worksPage/works_pic/7.jpg';
import pic_7 from '../Assets/worksPage/works_pic/8.jpg';
import pic_8 from '../Assets/worksPage/works_pic/9.jpg';
import pic_9 from '../Assets/worksPage/works_pic/10.jpg';
import pic_10 from '../Assets/worksPage/works_pic/11.jpg';
import pic_11 from '../Assets/worksPage/works_pic/12.jpg';
import pic_12 from '../Assets/worksPage/works_pic/13.jpeg';

import partener_2 from '../Assets/parteners/Group 1317.svg';
import partener_3 from '../Assets/parteners/Group 1703.svg';
import partener_4 from '../Assets/parteners/Group 1333.svg';
import partener_5 from '../Assets/parteners/Group 1701.svg';
import partener_6 from '../Assets/parteners/Group 1317.svg';
import partener_7 from '../Assets/parteners/Group 1700.svg';
import partener_8 from '../Assets/parteners/4.svg';
import partener_9 from '../Assets/parteners/Group 1320.svg';
import partener_10 from '../Assets/parteners/3.svg'; 



export const works = [
{
    id:0,
    title:<>Diriyah Gate<br></br>Launching Event</>,
    client:partener_8,
    ScopeW:'Creative Content Consultation, Visual ProductionsPR & Media, Creative Guest Journey Experience,Construction Visual Monitoring.',
    Description:'Diriyah witnessed the Kingdom’s jewel, a legendary ceremony befitting the kings and heroes, honoring the Custodian of the Two Holy Mosques and HRH Crown Prince to launch the Diriyah Gate Project. Jewel of the Kingdom showcased the greatness of the birthplace of Saudi Arabia in a magnificent way from life shows, fireworks, and musical performance, Sifr was once of the main pillars of that unforgettable night.',
    image:pic_1,
    video:'https://www.dropbox.com/s/qhm0e2hu0uveodc/DGDA-Launch%20Event%20Jewel%20of%20the%20Kingdom-1080hd.mp4?dl=0',

},{
    id:1,
    title:<>E-Visa<br></br>Launching</>,
    client:partener_2,
    ScopeW:'Creative Content Consultation, Brand Management, Guidelines Strategy Creation.',
    Description:'Saudi Arabia is opening its doors to visitors from all over the world, tourists will obtain a multiple-entry visa valid for a year that will allow them to stay in the country for up to 90 days. The new visa system is a historic milestone in opening Saudi Arabia to tourism. International visitors will discover the warm hospitality of Saudi people: our rich heritage, vibrant culture, and diverse and breath-taking landscapes.',
    image:pic_2,
    video:'https://www.dropbox.com/s/5lndsicz1oy81u7/E-Visa%20launching%20meeting%20%28MOT%29.mp4?dl=0',

},{
    id:2,
    title:<>Historical<br></br>Jeddah</>,
    client:partener_3,
    ScopeW:'Visual Productions, Creative Content, Visitor’s Experience, Media & PR.',
    Description:'The Historic Jeddah Season is a street celebration of the Historic Center of the city, where art, technology and a unique visual journey to enhance the visitors’ experience. Immersing their senses in the rich history of the Historic Jeddah and the decades of heritage through its five key offerings – art, culture, food, entertainment and retail experiences.',
    image:pic_3,
    video:'https://www.dropbox.com/sh/q695z0hlf7g5n5m/AABjSrhKqI4C_fRjqP2j7MFva?dl=0',
},{
    id:3,
    title:<>COVID-19<br></br>Campaign</>,
    client:partener_4,
    ScopeW:'Creative Content, Visual Productions,Storytelling, Script Writing, Campaign Management.',
    Description:'We took a part of the continuation of the awareness campaign on the corona-virus, which was launched by Ministry of Health in conjunction with the outbreak of the virus. As the campaign aims to educate all members of society about COVID-19 and guide them with sound awareness behavior that contributes to prevent it and limit its spread.',
    image:pic_4,
    video:'https://www.dropbox.com/s/kyfrpmd7fjw1mjb/200412-MOH-Message%20to%20Threaten-V06-Final.mp4?dl=0',
},{
    id:4,
    title:<>Cartier<br></br>Ramadan TVC</>,
    client:partener_5,
    ScopeW:'3D mapping, Aerial Shooting',
    Description:'Manifesting the birthplace of Saudi Arabia by showcasing its beautiful traditional architecture that expressibly form the Saudi culture. It recognized by UNESCO in 2010, is Al-Turaif Historical District, the remains of a settlement that dates back to the 15th century. Located in the north-western outskirts of the capital, Riyadh, it is one of the Kingdom’s oldest heritage sites, though its potential was only recognized relatively recently.',
    image:pic_5,
    video:'https://www.youtube.com/watch?v=FXphh9w0n4s',
},{
    id:5,
    title:<>Historical<br></br>Jeddah</>,
    client:partener_6,
    ScopeW:'Media Coverage, Visual Productions.',
    Description:'The World Tourism Organization (UNWTO) is the United Nations agency responsible for the promotion of responsible, sustainable and universally accessible tourism. In the 23 General Assembly in Russia, Saudi Arabia participated in promoting the tourism to the world by announcing the opening of its doors through the UNWTO in St. Petersburg.',
    image:pic_6,
    video:'https://www.dropbox.com/s/csm3s8ve1z4con3/UNWTO%20in%20Russia%20%20%28MOT%29.mp4?dl=0',
},{
    id:6,
    title:<>Clash <span style={{fontWeight:100,fontFamily: 'Pill_Gothic_600mg_regular'}}>on the</span> Dunes<br></br>Boxing Conference</>,
    client:partener_7,
    ScopeW:'Visual Productions, Media Productions.',
    Description:'The eyes of the sporting world were on the jewel of the kingdom “ Diriyah “, as defending world heavyweight champion Andy Ruiz Jr. and challenger Anthony Joshua weighed in for their Clash on the Dunes bout. On a rainy night in Diriyah, Anthony regained his world heavyweight titles after a unanimous points decision from the judges over Andy Ruiz Jr. An epic night of boxing in Saudi Arabia comes to an end.',
    image:pic_7,
    video:'',
},{
    id:7,
    title:<>Atturaif<br></br>Beauty</>,
    client:partener_8,
    ScopeW:'Creative Visual Productions, Library Creation, Content Creation, Presentation Visuals.',
    Description:'Manifesting the birthplace of Saudi Arabia by showcasing its beautiful traditional architecture that expressibly form the Saudi culture. It recognized by UNESCO in 2010, is Atturaif Historical District, the remains of a settlement that dates back to the 15th century. Located in the north-western outskirts of the capital, Riyadh, it is one of the Kingdom’s oldest heritage sites, though its potential was only recognized relatively recently',
    image:pic_8,
    video:'https://www.dropbox.com/s/98zmszpxkpqe2gz/180829_Atturaif%20Beauty_Sifr%20Studio.mp4?dl=0',
},{
    id:8,
    title:<>ZTC<br></br>Conference</>,
    client:partener_9,
    ScopeW:'Media Production, Social Media Planning, Visual Productions, Creative Content.',
    Description:'ZAKAT & Tax Conference took a place in discussions of key tax developments taking place at national, regional and global levels, and their implications on business activities. It’s also focused on zakat by providing a platform to discuss ways to overcome challenges relating to zakat calculation methods and accounting standards.',
    image:pic_9,
    video:'https://www.dropbox.com/s/7cfclbshtye3hj1/GAZT%20%E2%80%93%20ZTC%20conference%20%28GAZT%29.mp4?dl=0',
},{
    id:9,
    title:<>Made in Saudi<br></br>Campaign</>,
    client:partener_10,
    ScopeW:'Campaign Planning, Visual Production, High-end Production.',
    Description:'“ Made in Saudi “ , this was the main concept of this digital campaign is to aware people of one of Saudi Arabia’s vision 2030, by localizing more than 50% of the Kingdom’s spending on defense requirements, promoting military spending efficiency, and propelling the industry through centralized research and development',
    image:pic_10,
    video:'https://www.dropbox.com/s/why519j77m6ltwk/TEASER-V.05-Final-02.mp4?dl=0',
},{
    id:10,
    title:<>Russia<br></br>UNTWO</>,
    client:partener_2,
    ScopeW:'Media Coverage, Visual Productions.',
    Description:'The Kingdom of Saudi Arabia on October, 2019 participated in the tourism ministers’ meeting of the G20 that was held in Japan, in the presence of His Excellency Mr. Ahmad Al Khatib, as reaffirming the role of tourism as a driver of economic growth and contributor to the SDGs, opinions were exchanged on mutual issues such as tourism management that contributes to tourists and regional communities, and innovations and advanced technologies that promote sustainable tourism.',
    image:pic_11,
    video:'',
},{
    id:11,
    title:<>Formula-E<br></br>Activation</>,
    client:partener_8,
    ScopeW:'Media Coverage, Visual Productions.',
    Description:'The Diriyah ePrix is a race of the single-seater, electrically powered Formula E championship, held in Diriyah, Saudi Arabia. It was first held as part of the 2018–19 season and was the first Formula E race to be held in the Middle East. When beauty meets history. Capturing the thrilled moments of FORMULA-E championship in historic Diriyah.',
    image:pic_12,
    video:'https://www.dropbox.com/s/dc7bni3gpvucffg/HRH%20Atturaif.mp4?dl=0',
}
]