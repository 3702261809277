import React from 'react';
import'./DimensionTitle.scss';

export default function DimensionTitle({logo,content}){

    return(
        <div className="DimensionTitle">
             <div className="DT_logo">
                 <img src={logo} alt="logo"></img>
             </div>
             <div className="DT_content">
                 <span>
                     {content}
                 </span>
            </div>
        </div>
    )
}