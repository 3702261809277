import React from 'react';
import './Navbar.scss';
import Logo from '../LOGO/Logo';
import { OverlayContext } from '../../App';
import { NavLink, useLocation } from 'react-router-dom';
export default function Navbar(){
    const {Overlay_open , SetOverlay_open} = React.useContext(OverlayContext);
    let location = useLocation();
    return(
    <div className={Overlay_open || location.pathname==='/Contacts' ?"navbar black" :"navbar" }>
         <div className="Logo">
          <NavLink to="/" exact={true} className="link logolink"><Logo></Logo></NavLink>
         </div>
         
        <div className="menu_open" onClick={()=>{SetOverlay_open(!Overlay_open)}}>
            <div className="circle">
            <svg className="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 53">
                <path d="M3.13,35.325A25,25,0,0,0,51.53,26.5" fill="none" className="ic-path" style={{strokeDashoffset:0, strokeDasharray:'none' , stroke:!Overlay_open?"#E91A4D" :"#000"}}></path>
                <path d="M3.13,17.674A25,25,0,0,1,51.53,26.5" fill="none" className="ic-path" style={{strokeDashoffset:0, strokeDasharray:'none', stroke:!Overlay_open?"#E91A4D" :"#000"}}></path>
                <g>
                    <circle cx="37.516" cy="25.7" r="3.938" style={{opacity: Overlay_open?1 :0}}></circle>
                    <circle cx="25.699" cy="25.7" r="3.938" style={{opacity: Overlay_open?1 :0}}></circle>
                    <circle cx="13.883" cy="25.7" r="3.938" style={{opacity: Overlay_open?1 :0}}></circle>
                </g>
            </svg>            
            </div>
            <div className={Overlay_open ?"open_text close" :"open_text"}>
                    <span>{!Overlay_open ?"menu" :"close"}</span>            
            </div>
         </div>
    </div>
    )
}