import React from 'react';
import'./DimensionText.scss';


export default function DimensionText({title , text1 , text2 , colortext2}){

return(
    <div className="DimensionText">
         <div className="DT_title">
              <h1>{title}</h1>
         </div>
         {/* <div className="DT_text1">
              <span>{text1}</span>
         </div> */}
         <div className="DT_text2">
              <span style={{color:colortext2}}>{text2}</span>
         </div>
    </div>
)
}