import React from 'react';
import './Logo.scss';


export default function Logo(){


return(
    <svg className="logo_svg" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	           viewBox="0 0 1000 604" style={{enableBackground:'new 0 0 1000 604' }} >
        <path className="st0" d="M26.2,536.4c-2.5,0-4.8,0.4-7,1.1c-2.2,0.7-4.1,1.8-5.8,3.1c-1.7,1.3-3,3-3.9,4.9c-1,2-1.4,4.2-1.4,6.5v27.6
            c0,2.6,0.5,4.9,1.4,6.9c0.9,2,2.2,3.6,3.8,4.9c1.6,1.3,3.6,2.3,5.8,2.9c2.2,0.7,4.6,1,7.2,1c3.5,0,6.9-0.5,10-1.4
            c3.2-0.9,5.9-2.4,8.2-4.5l2.8,4.6c-2.9,2.5-6.1,4.3-9.6,5.3c-3.5,1-7.3,1.5-11.4,1.5c-3.5,0-6.7-0.5-9.6-1.4c-2.9-0.9-5.5-2.2-7.6-4
            c-2.2-1.8-3.9-4-5.1-6.6c-1.2-2.6-1.8-5.7-1.8-9.1v-27.6c0-3.3,0.6-6.2,1.9-8.8c1.3-2.6,3-4.8,5.2-6.6c2.2-1.8,4.8-3.2,7.7-4.2
            c2.9-1,6-1.5,9.3-1.5c8.9,0,16,2.8,21.1,8.4l-3.5,4.3c-2.1-2.3-4.7-4-7.7-5.3C33.1,537.1,29.8,536.4,26.2,536.4"/>
        <path className="st0" d="M78.5,549.9c1.9,0,3.6,0.2,5.2,0.6l-1.1,5.8c-0.7-0.1-1.5-0.3-2.4-0.4c-0.9-0.1-1.7-0.1-2.4-0.1
            c-1.8,0-3.4,0.5-4.9,1.3c-1.5,0.9-2.7,2.1-3.7,3.6c-1,1.5-1.8,3.2-2.4,5c-0.6,1.8-0.8,3.7-0.8,5.6v28.5H60v-49h5l0.6,10.3
            c0.5-1.5,1.1-2.9,1.9-4.2c0.8-1.4,1.8-2.6,2.9-3.6c1.1-1,2.3-1.9,3.7-2.5C75.5,550.2,77,549.9,78.5,549.9"/>
        <path className="st0" d="M111.2,595.4c1.8,0,3.5-0.2,5-0.5c1.6-0.3,3-0.7,4.2-1.2c1.3-0.5,2.4-1,3.3-1.5c0.9-0.5,1.7-1,2.2-1.5l2.9,4.2
            c-2.3,2.1-5.1,3.6-8.3,4.5c-3.2,1-6.4,1.5-9.6,1.5c-2.9,0-5.6-0.4-8.2-1.1c-2.6-0.7-5-1.8-7-3.2c-2-1.4-3.6-3.2-4.8-5.4
            c-1.2-2.2-1.8-4.7-1.8-7.7v-17.1c0-2.5,0.5-4.7,1.6-6.7c1-2,2.5-3.8,4.3-5.2c1.8-1.4,4-2.5,6.4-3.4c2.5-0.8,5.1-1.2,7.9-1.2
            c2.7,0,5.2,0.4,7.5,1.2c2.3,0.8,4.2,1.9,5.9,3.4c1.7,1.4,3,3.2,3.9,5.1c0.9,2,1.4,4.2,1.4,6.7v6.6l-33.1,4.8v5.8
            c0,2,0.4,3.7,1.3,5.2c0.9,1.5,2.1,2.7,3.6,3.7c1.5,1,3.2,1.7,5.1,2.2C107.1,595.2,109.1,595.4,111.2,595.4 M109.3,555.4
            c-1.9,0-3.7,0.3-5.4,0.8c-1.7,0.5-3.2,1.3-4.5,2.3c-1.3,1-2.3,2.2-3.1,3.5c-0.8,1.3-1.1,2.8-1.1,4.4v6.5l27.1-3.9v-2.8
            c0-3.1-1.2-5.7-3.6-7.7C116.1,556.4,113,555.4,109.3,555.4"/>
        <path className="st0" d="M158.3,549.9c5.3,0,9.5,1.5,12.6,4.4c3.1,2.9,4.6,7.1,4.6,12.4v33.2h-5.3l-0.6-6.7c-1.9,3-4.2,5-6.7,6
            c-2.6,1-5.4,1.5-8.6,1.5c-2.3,0-4.5-0.3-6.4-1c-2-0.7-3.7-1.6-5.1-2.9c-1.5-1.3-2.6-2.9-3.4-4.7c-0.8-1.8-1.2-3.9-1.2-6.1v-9.5
            l31.5-5.5v-4.3c0-7.6-3.9-11.4-11.6-11.4c-1.6,0-3.2,0.2-4.8,0.5c-1.6,0.3-3.1,0.8-4.5,1.2c-1.4,0.5-2.7,1-3.8,1.6
            c-1.2,0.6-2.1,1.1-2.9,1.5l-2.1-4.6c0.9-0.6,2.1-1.2,3.5-1.9c1.4-0.7,3-1.3,4.6-1.9c1.6-0.6,3.3-1.1,5.1-1.4
            C154.9,550.1,156.6,549.9,158.3,549.9 M169.5,575.8l-25.6,4.5v5.4c0,1.5,0.3,2.9,0.9,4.1c0.6,1.2,1.4,2.2,2.4,3
            c1,0.8,2.2,1.4,3.6,1.9c1.4,0.4,2.9,0.6,4.4,0.6c1.7,0,3.3-0.3,5-0.8c1.7-0.5,3.2-1.3,4.6-2.2c1.4-0.9,2.5-2.1,3.3-3.5
            c0.9-1.4,1.3-2.9,1.3-4.6V575.8z"/>
        <path className="st0" d="M205.7,594.8c2,0,3.9-0.2,5.7-0.7c1.8-0.5,3.2-0.9,4-1.3l1.6,4.7c-1.1,0.5-2.7,1.1-4.8,1.7
            c-2.1,0.7-4.3,1-6.6,1c-3.9,0-7-1.1-9.1-3.1c-2.2-2.1-3.2-5.1-3.2-9v-32.3h-9.3v-4.9h9.3V536h5.9v14.9H213v4.9h-13.8v32
            c0,2.1,0.6,3.8,1.6,5.1C202,594.2,203.6,594.8,205.7,594.8"/>
        <path className="st0" d="M227.3,531.9h6.6v7.4h-6.6V531.9z M227.8,550.9h5.8v49h-5.8V550.9z"/>
        <polygon className="st0" points="267.5,592.6 283.5,550.9 289.9,550.9 270.2,599.9 264.7,599.9 245,550.9 251.4,550.9 "/>
        <path className="st0" d="M319.2,595.4c1.8,0,3.5-0.2,5-0.5c1.6-0.3,3-0.7,4.2-1.2c1.3-0.5,2.4-1,3.3-1.5c0.9-0.5,1.7-1,2.2-1.5l2.9,4.2
            c-2.3,2.1-5.1,3.6-8.3,4.5c-3.2,1-6.4,1.5-9.6,1.5c-2.9,0-5.6-0.4-8.2-1.1c-2.6-0.7-5-1.8-7-3.2c-2-1.4-3.6-3.2-4.8-5.4
            c-1.2-2.2-1.8-4.7-1.8-7.7v-17.1c0-2.5,0.5-4.7,1.6-6.7c1-2,2.5-3.8,4.3-5.2c1.8-1.4,3.9-2.5,6.4-3.4c2.5-0.8,5.1-1.2,7.9-1.2
            c2.7,0,5.2,0.4,7.5,1.2c2.3,0.8,4.2,1.9,5.9,3.4c1.7,1.4,3,3.2,3.9,5.1c0.9,2,1.4,4.2,1.4,6.7v6.6l-33.1,4.8v5.8
            c0,2,0.5,3.7,1.3,5.2c0.9,1.5,2.1,2.7,3.6,3.7c1.5,1,3.2,1.7,5.1,2.2C315.1,595.2,317.1,595.4,319.2,595.4 M317.3,555.4
            c-1.9,0-3.7,0.3-5.4,0.8c-1.7,0.5-3.2,1.3-4.5,2.3c-1.3,1-2.3,2.2-3.1,3.5c-0.8,1.3-1.1,2.8-1.1,4.4v6.5l27.1-3.9v-2.8
            c0-3.1-1.2-5.7-3.6-7.7C324.1,556.4,321,555.4,317.3,555.4"/>
        <path className="st0" d="M391.8,531.9c3.5,0,6.7,0.4,9.8,1.4c3.1,0.9,5.8,2.2,8.1,4c2.3,1.8,4.2,4,5.5,6.6c1.4,2.6,2,5.7,2,9.1v25.7
            c0,3.6-0.7,6.7-2.1,9.3c-1.4,2.6-3.3,4.8-5.6,6.5c-2.4,1.7-5.1,3-8.2,3.9c-3.1,0.9-6.5,1.3-10,1.3h-19.9v-68H391.8z M411.5,553.1
            c0-2.5-0.5-4.8-1.6-6.7c-1.1-2-2.5-3.6-4.3-5c-1.8-1.3-3.9-2.3-6.2-3c-2.4-0.7-4.8-1-7.4-1h-14.5v57h14.1c2.7,0,5.3-0.3,7.7-0.9
            c2.4-0.6,4.5-1.5,6.4-2.8c1.9-1.3,3.3-2.9,4.4-4.9c1.1-2,1.6-4.3,1.6-6.9V553.1z"/>
        <path className="st0" d="M450.1,549.9c1.9,0,3.6,0.2,5.2,0.6l-1.1,5.8c-0.7-0.1-1.5-0.3-2.4-0.4c-0.9-0.1-1.7-0.1-2.4-0.1
            c-1.8,0-3.4,0.5-4.9,1.3c-1.5,0.9-2.7,2.1-3.7,3.6c-1,1.5-1.8,3.2-2.4,5c-0.6,1.8-0.8,3.7-0.8,5.6v28.5h-5.9v-49h5l0.6,10.3
            c0.5-1.5,1.1-2.9,1.9-4.2c0.8-1.4,1.8-2.6,2.9-3.6c1.1-1,2.3-1.9,3.7-2.5C447.2,550.2,448.6,549.9,450.1,549.9"/>
        <path className="st0" d="M464.6,531.9h6.6v7.4h-6.6V531.9z M465.1,550.9h5.8v49h-5.8V550.9z"/>
        <polygon className="st0" points="504.8,592.6 520.8,550.9 527.2,550.9 507.5,599.9 502,599.9 482.3,550.9 488.7,550.9 "/>
        <path className="st0" d="M556.5,595.4c1.8,0,3.5-0.2,5-0.5c1.6-0.3,3-0.7,4.2-1.2c1.3-0.5,2.4-1,3.3-1.5c0.9-0.5,1.7-1,2.2-1.5l2.9,4.2
            c-2.3,2.1-5.1,3.6-8.3,4.5c-3.2,1-6.4,1.5-9.6,1.5c-2.9,0-5.6-0.4-8.2-1.1c-2.6-0.7-5-1.8-7-3.2c-2-1.4-3.6-3.2-4.8-5.4
            c-1.2-2.2-1.8-4.7-1.8-7.7v-17.1c0-2.5,0.5-4.7,1.6-6.7c1-2,2.5-3.8,4.3-5.2c1.8-1.4,4-2.5,6.4-3.4c2.5-0.8,5.1-1.2,7.9-1.2
            c2.7,0,5.2,0.4,7.5,1.2c2.3,0.8,4.2,1.9,5.9,3.4c1.7,1.4,3,3.2,4,5.1c0.9,2,1.4,4.2,1.4,6.7v6.6l-33.1,4.8v5.8c0,2,0.4,3.7,1.3,5.2
            c0.9,1.5,2.1,2.7,3.6,3.7c1.5,1,3.2,1.7,5.1,2.2C552.4,595.2,554.4,595.4,556.5,595.4 M554.6,555.4c-1.9,0-3.7,0.3-5.4,0.8
            c-1.7,0.5-3.2,1.3-4.5,2.3c-1.3,1-2.3,2.2-3.1,3.5c-0.8,1.3-1.1,2.8-1.1,4.4v6.5l27.1-3.9v-2.8c0-3.1-1.2-5.7-3.6-7.7
            C561.4,556.4,558.3,555.4,554.6,555.4"/>
        <path className="st0" d="M609.5,549.8c5.4,0,9.6,1.5,12.5,4.4c2.9,3,4.4,7.1,4.4,12.5v33.1h-5.9v-32.5c0-3.8-1-6.8-3.1-8.9
            c-2.1-2.1-5-3.2-8.8-3.2c-1.9,0-3.8,0.4-5.7,1.1c-2,0.8-3.7,1.8-5.3,3.1c-1.6,1.3-2.9,2.8-3.9,4.5c-1,1.7-1.5,3.5-1.5,5.4v30.4h-6
            v-49h5.6l0.4,9.5c1.9-3.2,4.4-5.8,7.3-7.7C602.2,550.8,605.6,549.8,609.5,549.8"/>
        <polygon className="st0" points="709.4,531.9 715.3,531.9 715.3,599.9 709.4,599.9 709.4,569.8 670.5,569.8 670.5,599.9 664.7,599.9 
            664.7,531.9 670.5,531.9 670.5,564.3 709.4,564.3 "/>
        <path className="st0" d="M749.5,549.8c2.7,0,5.3,0.4,7.7,1.2c2.4,0.8,4.6,1.9,6.4,3.4c1.8,1.5,3.3,3.3,4.3,5.4c1.1,2.1,1.6,4.4,1.6,7
            v16.6c0,2.6-0.6,5-1.6,7.1c-1.1,2.1-2.6,3.9-4.4,5.5c-1.9,1.5-4,2.7-6.4,3.5c-2.4,0.8-4.9,1.2-7.5,1.2c-2.6,0-5.1-0.4-7.5-1.2
            c-2.4-0.8-4.6-2-6.4-3.5c-1.9-1.5-3.4-3.3-4.5-5.4c-1.1-2.1-1.7-4.5-1.7-7.2v-16.6c0-2.6,0.6-5,1.7-7.1c1.1-2.1,2.6-3.9,4.4-5.4
            c1.9-1.5,4-2.6,6.4-3.4C744.3,550.2,746.9,549.8,749.5,549.8 M763.7,566.9c0-1.8-0.4-3.4-1.2-4.9c-0.8-1.4-1.9-2.7-3.2-3.7
            c-1.3-1-2.8-1.8-4.5-2.3c-1.7-0.5-3.4-0.8-5.2-0.8c-1.8,0-3.6,0.3-5.3,0.8c-1.7,0.5-3.3,1.3-4.6,2.3c-1.3,1-2.4,2.2-3.2,3.7
            c-0.8,1.4-1.2,3.1-1.2,4.9v16.6c0,1.9,0.4,3.5,1.2,5c0.8,1.4,1.9,2.7,3.2,3.7c1.4,1,2.9,1.8,4.6,2.3c1.7,0.5,3.5,0.8,5.3,0.8
            c1.8,0,3.5-0.3,5.2-0.8c1.7-0.5,3.2-1.3,4.5-2.3c1.3-1,2.4-2.2,3.2-3.7c0.8-1.5,1.2-3.1,1.2-4.9V566.9z"/>
        <path className="st0" d="M816.4,550.9h5.9v32.8c0,2.6-0.5,5-1.6,7.1c-1.1,2.1-2.6,3.9-4.4,5.4c-1.8,1.5-3.9,2.6-6.3,3.5
            c-2.4,0.8-4.9,1.2-7.6,1.2c-2.6,0-5.1-0.4-7.5-1.2c-2.4-0.8-4.5-1.9-6.3-3.5c-1.8-1.5-3.3-3.3-4.4-5.4c-1.1-2.1-1.6-4.5-1.6-7.1
            v-32.8h5.9v32.8c0,1.8,0.4,3.4,1.1,4.8c0.8,1.4,1.8,2.7,3.1,3.7c1.3,1,2.8,1.8,4.4,2.4c1.7,0.5,3.4,0.8,5.2,0.8
            c1.8,0,3.5-0.3,5.2-0.8c1.7-0.5,3.2-1.3,4.5-2.3c1.3-1,2.4-2.2,3.2-3.7c0.8-1.5,1.2-3.1,1.2-4.9V550.9z"/>
        <path className="st0" d="M853.6,572.1c2.1,0.7,4.1,1.4,6,2.2c1.9,0.8,3.6,1.7,5,2.8c1.5,1.1,2.6,2.3,3.4,3.8c0.8,1.5,1.2,3.3,1.2,5.4
            c0,2.6-0.5,4.8-1.4,6.6c-0.9,1.8-2.2,3.3-3.9,4.5c-1.7,1.2-3.6,2-5.8,2.5s-4.6,0.8-7.1,0.8c-3.5,0-6.8-0.7-9.9-2
            c-3.2-1.3-5.9-3.2-8.2-5.6l2.8-4.3c1.9,1.9,4.2,3.5,7.1,4.7c2.9,1.2,5.7,1.9,8.5,1.9c1.7,0,3.3-0.1,4.8-0.4c1.5-0.2,2.8-0.7,3.9-1.3
            c1.1-0.7,2-1.6,2.6-2.6c0.6-1.1,0.9-2.5,0.9-4.2c0-1.7-0.4-3-1.2-4c-0.8-1-1.9-1.9-3.2-2.6c-1.3-0.7-2.8-1.3-4.4-1.8
            c-1.6-0.5-3.2-0.9-4.8-1.4c-2.1-0.7-4-1.4-5.8-2c-1.8-0.7-3.4-1.6-4.7-2.6c-1.3-1-2.4-2.3-3.2-3.8c-0.8-1.5-1.2-3.3-1.2-5.5
            c0-2.3,0.4-4.3,1.3-6c0.9-1.7,2.1-3,3.6-4.1c1.5-1.1,3.3-1.9,5.4-2.4c2.1-0.5,4.3-0.7,6.7-0.7c1.2,0,2.5,0.1,3.9,0.4
            c1.4,0.3,2.8,0.7,4.1,1.2c1.4,0.5,2.7,1.1,4,1.8c1.3,0.7,2.5,1.4,3.5,2.1l-2.8,5c-1.8-1.3-3.8-2.5-6.1-3.5c-2.3-1-4.5-1.5-6.8-1.5
            c-1.6,0-3.1,0.1-4.4,0.4c-1.3,0.2-2.5,0.6-3.5,1.2c-1,0.6-1.7,1.4-2.2,2.4c-0.5,1-0.8,2.3-0.8,3.8c0,1.5,0.4,2.8,1.2,3.7
            c0.8,0.9,1.8,1.7,3,2.3c1.2,0.6,2.6,1.1,4.1,1.5C850.7,571.2,852.2,571.6,853.6,572.1"/>
        <path className="st0" d="M900.2,595.4c1.8,0,3.5-0.2,5-0.5c1.6-0.3,3-0.7,4.2-1.2c1.3-0.5,2.4-1,3.3-1.5c0.9-0.5,1.7-1,2.2-1.5l2.9,4.2
            c-2.3,2.1-5.1,3.6-8.3,4.5c-3.2,1-6.4,1.5-9.6,1.5c-2.9,0-5.6-0.4-8.2-1.1c-2.6-0.7-5-1.8-7-3.2c-2-1.4-3.6-3.2-4.8-5.4
            c-1.2-2.2-1.8-4.7-1.8-7.7v-17.1c0-2.5,0.5-4.7,1.6-6.7c1-2,2.5-3.8,4.3-5.2c1.8-1.4,4-2.5,6.4-3.4c2.5-0.8,5.1-1.2,7.9-1.2
            c2.7,0,5.2,0.4,7.5,1.2c2.3,0.8,4.2,1.9,5.9,3.4c1.7,1.4,3,3.2,3.9,5.1c0.9,2,1.4,4.2,1.4,6.7v6.6l-33.1,4.8v5.8
            c0,2,0.4,3.7,1.3,5.2c0.9,1.5,2.1,2.7,3.6,3.7c1.5,1,3.2,1.7,5.1,2.2C896.1,595.2,898.1,595.4,900.2,595.4 M898.3,555.4
            c-1.9,0-3.7,0.3-5.4,0.8c-1.7,0.5-3.2,1.3-4.5,2.3c-1.3,1-2.3,2.2-3.1,3.5c-0.8,1.3-1.1,2.8-1.1,4.4v6.5l27.1-3.9v-2.8
            c0-3.1-1.2-5.7-3.6-7.7C905.1,556.4,902,555.4,898.3,555.4"/>
        <path className="st0" d="M419.2,112.3c27.7,0,49.7-14.5,49.7-42.1c0-28.3-22-44.6-49.7-44.6c-27.7,0-49.7,16.3-49.7,44.6
            C369.6,97.8,391.6,112.3,419.2,112.3"/>
        <path className="st0" d="M949.3,140.6c-40.2,0-78.6,34-91.8,78.6v-72.3H739.9v44h35.8v226.3h-35.8h-31.6h-58.5V190.9h58.5v-44h-58.5
            V77.1c0-33.3,8.2-47.8,28.3-47.8c4.4,0,8.2,1.9,10.7,3.8c-10.1,5-11.9,17-11.9,20.7c0,20.1,17,28.3,35.2,28.3S748,70.1,748,45.6
            c0-23.3-20.7-43.4-67.3-43.4c-68.5,0-112.5,37.7-112.5,108.1v36.5h-70.7v44h70.7v226.3h-35.8h-30.7h-35.8V146.8H348.8v44H384v226.3
            h-35.2h-62c20.3-21.8,31.2-51.1,31.2-86.6c0-140.8-216.9-130.8-216.9-218.8c0-35.2,20.7-55.3,61-55.3c52.8,0,76.7,38.4,89.9,82.4h44
            V11.2h-30.2l-7.5,45.9C230.6,18.7,186,8,150.1,8C52.7,8,8.7,63.3,8.7,128.7c0,154,216.9,125.7,216.9,225.1
            c0,37.7-25.1,58.5-72.3,58.5c-57.2,0-93.1-30.8-108.8-82.4h-44v87.3v40.4v3.7h348.3h152.8h30.7h176h31.6h177.9v-44h-60.4v-88
            c0-89.9,40.9-140.8,65.4-147.1c-2.5,5.7-3.1,10.1-3.1,15.1c0,17.6,13.2,34.6,38.4,34.6c25.1,0,41.5-18.9,41.5-44
            C999.6,161.9,985.7,140.6,949.3,140.6"/>
        <path className="st0" d="M988.3,575.9c0-14.4-11.7-26.1-26.1-26.1c-14.4,0-26.1,11.7-26.1,26.1c0,14.4,11.7,26.1,26.1,26.1
            C976.7,602,988.3,590.3,988.3,575.9"/>
    </svg>
)
}