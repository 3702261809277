import React from 'react';
import './DimensionSection.scss';
import { DimensionTitle, DimensionText } from '..';

export default function DimensionSection({data}){


    return(
        <div className="D_specifique">
        <DimensionTitle logo={data.logo} content={data.title_content}></DimensionTitle>
        <div className="D_content">
            {
             data.content.map((val,index)=>(
                <DimensionText {...val} key={index} colortext2={data.colortext2}></DimensionText>
             ))
            }
        </div>
     </div>
    )
}