import React from 'react';
import './StoryPage.scss';
import story_back from '../../Assets/stotypage/Tree-01.jpg';
import Tree from '../../Assets/stotypage/Tree-Sifr (1).png';
import gsap from 'gsap';
import { ScrollDown } from '../../component';
var position_tree =0;
var first = 0;
var touch_start=0;
export default function StoryPage(){

    const tree_Y = [-76 , -64 , -47 , -35 , -18 , -2];
    const sifr_story = [
        'If you look at Sifr you see nothing; but look through it you will see the world.',
        'Sifr brings into focus the great, organic sprawl of mathematics, and mathematics in turn the complex nature of things.',
        'From counting to calculating, from estimating the odds to knowing exactly when the tides in our affairs will crest, the shining tools of mathematics let us follow the tacking course everything takes through everything else - and all of their parts swing on the smallest of pivots, Sifr.',
        'The problem is that no matter how fine the weave, it trails off toward ever earlier causes and ever later affects, and our minds wants no sequence of dots to cover these gaps but a unified whole: a picture encircled with a frame. ',
        'This is the framework on being, within which our understanding works, nesting our experiences and making sense of them. ',
        'The completion is illusory — but we would as soon do without it as do without breathing because air is impure. Zero stands not for the closing of a ring: it is rather a gateway.'
    ];
    var Stext_ref = React.useRef(null);
    var Stext2_ref = React.useRef(null);
    const [storyC , SetstoryC] = React.useState([]);
    React.useEffect(()=>{ 
          position_tree =0;
          let ts = gsap.timeline();
          ts.to('.story_back',{duration:.5,opacity:.5});
          ts.to('.tree_pic',{duration:1,y:tree_Y[position_tree]+"%",onStart:()=>{SetstoryC(sifr_story.filter((val , index)=>(index<=position_tree)).map((val)=>(val)))}});
          ts.from('.story_content',{duration:1,opacity:0},'-=.7')
    },[]);
    React.useEffect(()=>{
      gsap.to(Stext_ref.current,{duration:1,height:Stext2_ref.current.offsetHeight,delay:.1,ease:"slow"})
    },[storyC]);

    const scroll_Story=(e)=>{
        if(first === 0){
            if(e.deltaY > 0){
             scroll_down();
            }else if(e.deltaY < 0){
             scroll_up();
            }
        }
    }
    const scroll_up=()=>{
        if(position_tree>0){
          first++;
          position_tree--;
          animation_trigged(position_tree);
          gsap.set('.scroll',{visibility:'visible'});
        }
      }
      const scroll_down=()=>{
        if(position_tree<tree_Y.length-1){
          first++;
          position_tree++;
          animation_trigged(position_tree);
          if(position_tree===tree_Y.length-1){
            gsap.set('.scroll',{visibility:'hidden'});
          }
        }
      }
    const animation_trigged=(position)=>{
        let t1 = gsap.timeline();
        t1.to('.tree_pic',{duration:1,
                             y:tree_Y[position]+"%",
                              ease:"slow",
                              onStart:()=>{SetstoryC(sifr_story.filter((val , index)=>(index<=position)).map((val)=>(val)));},
                              onComplete:()=>{first=0}});      
    }
    const touch_scroll =(e)=>{
    let touch_end = e.changedTouches[0].clientY;
    if(first === 0)
    if(touch_start>touch_end+5){
        scroll_down();
    }else if(touch_start<touch_end-5){
        scroll_up();
    }
    }

    return(
        <>
        <div className="StoryPage" onWheel={scroll_Story} onTouchStart={(e)=>{touch_start=e.touches[0].clientY}} onTouchEnd={touch_scroll}>
            <div className="story_backgroud">
                <img src={story_back} className="story_back" alt="story back" onLoad={()=>{console.log("loaded")}}></img>
            </div>
            <div className="story_tree">
                <div className="cadre">
                    <img src={Tree} className="tree_pic" alt="story tree"></img>
                </div>
            </div>
            <div className="story_content">
                 <h1 className="S_title">Sifr story</h1>
                 <div className="S_text" ref={Stext_ref}style={{height:0}}>
                     {
                         sifr_story.map((val , index)=>(
                               <span key={index}>{val} <br></br></span>
                         ))
                     }
                  </div>
            </div>
            <div className="story_content">
                  <div className="S_text op0" ref={Stext2_ref}>
                     {
                         storyC.map((val , index)=>(
                               <span key={index}>{val} <br></br></span>
                         ))
                     }
                  </div>
            </div>
        </div>
        <ScrollDown click_event={()=>{scroll_down()}}></ScrollDown>
        </>
    )
}