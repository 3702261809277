import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Homepage, Workspage,Dimensionspage,ContactPage, StoryPage, WorkdetailsPage} from '../Pages';
import {Transition , TransitionGroup} from 'react-transition-group';
import { work_leave } from '../Pages/WorkdetailsPage/WorkdetailsPage';

export default function AppRouter(){


    return(
        <Switch>
            <Route path="/" exact>
                <Homepage></Homepage>
            </Route>
            <Route path="/Works" exact>
                <Workspage></Workspage>
            </Route>
            <Route path="/Works/id" 
            render={
                ({location})=>{
                    const {key}=location;
                return( 
                <TransitionGroup component={null}>
                    <Transition
                    key={key}
                    onExit={()=>{work_leave()}}
                    timeout={{enter:1500 , exit:1500}}>
                    <Switch location={location}>
                        <Route exact path="/Works/id/:id"  component={WorkdetailsPage}></Route> 
                        <Route  path="**">
                            <Redirect to="/Works/id/0"></Redirect>
                        </Route>
                    </Switch>
                    </Transition>
                  </TransitionGroup>
                )
                //   <WorkdetailsPage></WorkdetailsPage> 
                }
            }>
            </Route>
            <Route path="/Dimensions" exact>
                <Dimensionspage></Dimensionspage>
            </Route>
            <Route path="/Contacts" exact>
                <ContactPage></ContactPage>
            </Route>
            <Route path="/Story" exact>
                <StoryPage></StoryPage>
            </Route>
            <Route path="**" exact>
                <Redirect to="/"></Redirect>
            </Route>       
        </Switch>
    )
}
