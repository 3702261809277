import React from 'react';
import './MenuLink.scss';
import gsap from 'gsap';

export const spliText =(Text)=>{
    return(   
          Text.split("").map((char,index)=>(
            <span className="letter" key={index}>
              {char}
            </span>
          ))
    )
}
export default function MenuLink({text , active}){

    var hovered = false;
    const over=(e)=>{
        if(!hovered){
          hovered=true;
          gsap.fromTo(e.target.childNodes,{opacity:.3},{duration:1,opacity:1,stagger:0.1,onComplete:()=>{hovered=false}})
        }
      }
    return(
        <span className={active?"sub_letter active":"sub_letter"} onMouseEnter={over}>
            {spliText(text)}
        </span>
    )
}
