import React, { useEffect, useState } from 'react';
import './App.scss';
import Footer from './component/Footer/Footer';
import Navbar from './component/Navbar/Navbar';
import gsap from 'gsap';
import { MenuOverlay } from './component';
import AppRouter from './Router/AppRouter';
import {works} from './Data/Works';
import {parteners} from './Data/parteners';
import {sifr_info} from './Data/sifr_info';
import { useLocation } from 'react-router-dom';
export const OverlayContext = React.createContext();
export const AppDataContext = React.createContext();
function App() {
  const [loading,Setloading]=React.useState(true);
  useEffect(
  ()=>  {
    let tl=setTimeout(()=>{
      Setloading(false);
      let t1 = gsap.timeline();
      t1.to('.item1',{duration:1,opacity:1 , delay:1});
      t1.to('.item3',{duration:1,y:-0});
      clearTimeout(tl);
    },500);
    if(location.pathname === '/'){
      Setfl(true);
    }
  },[]);
  const [Overlay_open , SetOverlay_open]=React.useState(false);
  const [firstLoad , Setfl]= React.useState(false);
  const worksdata=works;
  let location = useLocation();

  return (
   <div className="app">
     {
       loading ? <></>
       :<OverlayContext.Provider value={{Overlay_open , SetOverlay_open}}>
       <MenuOverlay></MenuOverlay>
       <div className="item1">
       <Navbar></Navbar>
       </div>
       <AppDataContext.Provider value={{worksdata , parteners,sifr_info,firstLoad , Setfl}}>
          <div className="App_Grid">
            <div className="item2">
                <AppRouter></AppRouter>
              </div>
              <div className={location.pathname.includes('/Works/id/')?"item3":"item3 high"}>
                <Footer></Footer>
              </div>
          </div>
        </AppDataContext.Provider>
    </OverlayContext.Provider>
     }
   </div>
  );
}

export default App;
