import React from 'react';
import './AnimatedText.scss';

export default function AnimatedText({Text , animate}){


    return(
        <h1 className={animate ?"AnimatedText animate" :"AnimatedText"}>
            <span className="sub_white">
                {Text}
            </span>
            {
                animate ?<><span className={animate ?"sub_red animate" :"sub_red"}>
                            {Text} 
                        </span> 
                        <span className={animate ?"sub_green animate" :"sub_green"}>
                            {Text} 
                        </span>
                        <span className={animate ?"sub_blue animate" :"sub_blue"}>
                            {Text} 
                        </span></>  :<></>
            }
        </h1>
    )
}