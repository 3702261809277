import React from 'react';
import './DimensionMain.scss';
import Logo from'../LOGO/Logo';
import Mina from '../../Assets/dimensionspage/Mina.png';
import studio from '../../Assets/dimensionspage/Studio.png';
export default function DimensionMain(){

    return(
        <>
    <div className="dimension_title">
        <span>Dimensions</span>
    </div>
    <div className="dimension_content">
    <div className="logos">
       <div className="mobile_line"></div>
      <div className="cepntent_pic logoside">
         <img alt="Studio" src={studio} ></img>
         <div className="content_text">
         Sifr the studio is where we ride on our own imaginary carpet to fly to the space &
         the infinite creativity through the Sifr Gateway to express fantasies into realties.
         </div>
      </div>
      <div className="center sifrlogo">
         <div className="line_container">
            <div className="line" style={{float:'right'}}></div>
         </div>
         <div className="cepntent_pic">
            <Logo></Logo>
         </div>
         <div className="line_container">
            <div className="line"></div>
         </div>
      </div>
      <div className="cepntent_pic logoside">
         <img alt="Studio" src={Mina}></img>
         <div className="content_text">
         When time could be seen in a 360 degrees through specialization, 
         in Sifr the Mina we have the engines to translate time into space to showcase it in a different dimension.
         </div>
      </div>
    </div>
    </div>
    </>
    )
}