import React from 'react';
import './Homepage.scss';
import {About , Home, ScrollDown} from '../../component';
import gsap from 'gsap';

var position=1;
export default function Homepage(){
        React.useEffect(()=>{
        position=1;
        },[]);
        const complete_black =(target)=>{
            target.classList.add("complete");
        }
        const closehome=()=>{
            let t1 = gsap.timeline({onStart:()=>{complete_black(document.getElementById("home_blackB"))} ,
                                                paused:true,
                                                onComplete:()=>{position=2;}});
            t1.to("#home_blackB",{duration:1 ,y:'0%',ease:'slow'});
            t1.to(".home_text",{duration:1,opacity:0,x:-100},'-=1');
            t1.set('.scroll',{visibility:'hidden'})
            t1.to('.home',{duration:.1,y:'-110%'})
            t1.fromTo('.About',{opacity:0},{opacity:1,y:0 , duration:.3},"-=.5");
            t1.to('.image_black',{duration:1, height:0})
            t1.fromTo('.content_about',{opacity:0,y:50},{duration:1,opacity:1,y:0},"-=.9")
            t1.fromTo('.text_about',{opacity:0,y:50},{duration:1,opacity:1,y:0},"-=1")
            return t1;
        }
        const openhome=()=>{
            let t1 = gsap.timeline({onComplete:()=>{position=1;} , paused:true});
            t1.to('.image_black',{duration:1, height:'100%'});
            t1.to('.text_about',{duration:1,opacity:0,y:50},"-=.5")
            t1.to('.content_about',{duration:1,opacity:0,y:50},"-=1")
            t1.to('.About',{opacity:0,y:'110%' , duration:.3});
            t1.to('.home',{duration:0,y:0},'-=.4')
            t1.to("#home_blackB",{duration:2 ,y:'110%',ease:'slow'});
            t1.to(".home_text",{duration:2,opacity:1,x:0},'-=2');
            t1.set('.scroll',{visibility:'visible'})
            return t1;

        }
    const scroll_home=(e)=>{
        if(e.deltaY > 0){
             if(position === 1){
                position=0;
                closehome().play();
             }
        }else if(e.deltaY < 0){
                if(position === 2){
                position=0;
                openhome().play();
             }
        }
    }
    let touch_start=0;
    const touch_scroll =(e)=>{
    let touch_end = e.changedTouches[0].clientY;
    if(touch_start>touch_end+5){
        if(position === 1){
            position=0;
            closehome().play();
        }
    }else if(touch_start<touch_end-5){
        if(position === 2){
            position=0;
            openhome().play();
        }
    }
    }

return(
    <>
    <div className="Homepage" onWheel={scroll_home} onTouchStart={(e)=>{touch_start=e.touches[0].clientY}} onTouchEnd={touch_scroll}>
     <Home></Home>
     <About></About>
    </div>
    <ScrollDown click_event={()=>{scroll_home({deltaY:10})}}></ScrollDown>
    </>
)
}