import React from 'react';
import {faTwitter , faInstagram , faLinkedinIn , faVimeoV} from '@fortawesome/free-brands-svg-icons';

export const sifr_info ={
  socialmedia:[  
    {
        link:'https://www.instagram.com/sifr_studio/',
        icone:faInstagram
    },    
    {
        link:'https://www.linkedin.com/company/sifr-studio',
        icone:faLinkedinIn
    },
    {
        link:'https://twitter.com/sifr_studio/',
        icone:faTwitter
    },
    {
        link:'https://vimeo.com/sifrstudio',
        icone:faVimeoV
    }],
    location:[<>View Square,Alyasmin district </>,<>Kingdom of Saudi Arabia,Riyadh</>],
    locationlLink:"https://www.google.com/maps/place/%D9%81%D9%8A%D9%88+%D8%B3%D9%83%D9%88%D9%8A%D8%B1+view+square%E2%80%AD/@24.8331375,46.6575626,15z/data=!4m5!3m4!1s0x0:0x32e4a8ce1e65c5ec!8m2!3d24.8331375!4d46.6575626?hl=en-sa",
    phonenumber:'+966550044920',
    email:'Hello@sifr.sa'
}