import React from 'react';
import './WorkdetailsPage.scss';
import { Link, useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { AppDataContext } from '../../App';
import AnimatedLink from '../../component/AnimatedLink/AnimatedLink';
export function work_leave(){
    let TL = gsap.timeline();
    TL.to('.Wd_pic',{duration:.5,x:-100,opacity:0,ease:"slow"});
    TL.to('.Wd_content > div',{duration:.3,x:-100,opacity:0,stagger:.1},'-=.4');
    gsap.to('.next',{duration:.7,x:150,opacity:0 ,ease:"slow"});
    gsap.to('.previous',{duration:.7,x:-150,opacity:0,ease:"slow"});
}
const initwork={    
    id:0,
    title:<></>,
    client:'',
    ScopeW:'',
    Description:'',
    image:'',
    video:'',
}
const prevwork={    
    title:<></>,
    image:''
}
const nextwork={    
    title:<></>,
    image:''
}
export default function WorkdetailsPage(props){
    let w_ref = React.useRef(null);
    let wpic_ref = React.useRef(null);
    let wcontent_ref = React.useRef(null);
    let wnext_ref = React.useRef(null);
    let wprevious_ref = React.useRef(null);
    let history = useHistory();
    const {worksdata}=React.useContext(AppDataContext);
    const [current_work , Setcw]=React.useState(initwork);
    const [next_work , Setnw]=React.useState(nextwork);
    const [previous_work , Setpw]=React.useState(prevwork);
    React.useEffect(()=>{
    let work = getWork_by_id(props.match.params.id);
    if(work ===null){
     history.push({pathname:'/Works/id/0'});
    }else{
        getnext_work(props.match.params.id);
        getprevious_work(props.match.params.id);
        work_open(props.location.state===undefined?0:props.location.state.fromworks);
    }
    
    },[]);
    const work_open=(delay)=>{
    let TL = gsap.timeline({delay: delay});
    TL.to(w_ref.current,{opacity:1,duration:.2});
    TL.from(wpic_ref.current,{duration:.8,x:100,opacity:0,ease:"slow"});
    TL.from(wcontent_ref.current.children,{duration:.3,x:100,opacity:0,stagger:.2},'-=.5');
    TL.from(wnext_ref.current,{duration:.5,x:150,opacity:0,ease:"slow"});
    TL.from(wprevious_ref.current,{duration:.5,x:-150,opacity:0,ease:"slow"},'-=.5');
    }
    const getWork_by_id=(id)=>{
        if(parseInt(id)>=0 && parseInt(id)<worksdata.length){
            Setcw(worksdata[parseInt(id)])
            return worksdata[parseInt(id)];
        }
        return null;
    }
    const getnext_work=(id)=>{
        let int_id = parseInt(id);
        if(int_id >=worksdata.length-1){
            Setnw(getshort_work(0));
        }else{
            Setnw(getshort_work(int_id+1));
        }
    }
    const getprevious_work=(id)=>{
        let int_id = parseInt(id);
        if(int_id === 0){
            Setpw(getshort_work(worksdata.length-1));
        }else{
            Setpw(getshort_work(int_id-1));
        }
    }
    const getshort_work=(id)=>{
      return {id:id,title:worksdata[id].title,image:worksdata[id].image};
    }
   
    return(
        <div className="WorkdetailsPage" ref={w_ref}>
            <div className="wd">
                <div className="Wd_image">
                   <img src={current_work.image}
                        ref={wpic_ref}
                        alt=""
                        className="Wd_pic"></img>
                </div>  
                <div className="Wd_content" ref={wcontent_ref}>
                    <div>
                        <h1>{current_work.title}</h1>
                    </div>
                    <div className="Wd_item">
                       <h3>Client</h3>
                       <img src={current_work.client}
                            alt="client"></img>
                    </div>
                    <div className="Wd_item">
                       <h3>Scope of Work</h3>
                       <div className="content">
                        <div className="content_line"></div>
                        <span>{current_work.ScopeW}</span>
                       </div>
                    </div>
                    <div className="Wd_item">
                       <h3>Description</h3>
                       <div className="content">
                        <div className="content_line"></div>
                        <span>
                        {current_work.Description}
                        </span>
                       </div>
                    </div>
                    <div className="Wd_item">
                        {current_work.video==='' ?
                        <></>:
                        <a href={current_work.video} target="_blank" rel="noopener noreferrer" className="info">
                            <AnimatedLink text="See more"></AnimatedLink>
                        </a>}
                    </div>
                </div>                  
            </div>
            <div className="Navigation_footer">
                <div className="work previous" ref={wprevious_ref}>
                    <Link to={{pathname:"/Works/id/"+previous_work.id , state:{fromworks:1}}}>
                        <img src={previous_work.image} alt="next work"></img> 
                        <div className="w_title">
                            <span>{previous_work.title}</span>
                        </div>                    
                    </Link>
                </div>
                <div className="back">
                    <Link to="/Works">
                     <span>BACK TO ALL</span> 
                    </Link>
                     
                </div>
                <div className="work next" ref={wnext_ref}>
                    <Link to={{pathname:"/Works/id/"+next_work.id , state:{fromworks:1}}}>
                     <img src={next_work.image} alt="previous work"></img> 
                     <div className="w_title">
                        <span>{next_work.title}</span>
                     </div>
                    </Link>
                </div>
            </div>
   
        </div>
    )
}