import React from'react';
import './AnimatedLink.scss';

export default function AnimatedLink({text}){

    return(
    <>
        <div className="select_text">
            <span>{text}</span>
        </div>
        <span className="btn_decor">
        <span className="line"></span>
        <span className="circle"></span>
        <span className="icon-circle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 53">
                <path d="M3.13,35.325A25,25,0,0,0,51.53,26.5" fill="none" stroke="#E91A4D" style={{strokeDashoffset: 0, strokeDasharray: null}}></path>
                <path d="M3.13,17.674A25,25,0,0,1,51.53,26.5" fill="none" stroke="#E91A4D" style={{strokeDashoffset: 0, strokeDasharray: null}}></path>
            </svg>                    
        </span>
        </span>                    
    </>
    )

}