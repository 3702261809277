import React from 'react';
import partener_1 from '../Assets/parteners/Group 1306.svg';
import partener_2 from '../Assets/parteners/Group 1310.svg';
import partener_3 from '../Assets/parteners/Group 1314.svg';
import partener_4 from '../Assets/parteners/Group 1317.svg';
import partener_6 from '../Assets/parteners/Group 1320.svg';
import partener_7 from '../Assets/parteners/Group 1329.svg';
import partener_8 from '../Assets/parteners/Group 1333.svg';
import partener_9 from '../Assets/parteners/Group 1336.svg';
import partener_11 from '../Assets/parteners/Group 1341.svg';
import partener_12 from '../Assets/parteners/Group 1344.svg';
import partener_13 from '../Assets/parteners/Group 1349.svg';
import partener_14 from '../Assets/parteners/Group 1354.svg';
//import partener_15 from '../Assets/parteners/Group 1359.svg';
import partener_16 from '../Assets/parteners/Group 1363.svg';
import partener_17 from '../Assets/parteners/Group 1509.svg';
import partener_18 from '../Assets/parteners/Group 1513.svg';
import partener_19 from '../Assets/parteners/Group 1517.svg';
import partener_20 from '../Assets/parteners/Group 1625.svg';
import partener_21 from '../Assets/parteners/Group 1700.svg';
import partener_22 from '../Assets/parteners/Group 1701.svg';
import partener_23 from '../Assets/parteners/Group 1702.svg';
import partener_24 from '../Assets/parteners/Group 1703.svg';
import partener_25 from '../Assets/parteners/Group 1704.svg';
import partener_26 from '../Assets/parteners/Group 1706.svg';
import partener_27 from '../Assets/parteners/Group 1707.svg';
import partener_38 from '../Assets/parteners/1.svg';
import partener_39 from '../Assets/parteners/2.svg';
import partener_40 from '../Assets/parteners/3.svg';
import partener_41 from '../Assets/parteners/4.svg';
import partener_42 from '../Assets/parteners/5.svg';
import partener_44 from '../Assets/parteners/7.svg';
export const parteners =[
    partener_1,
    partener_2,
    partener_3,
    partener_4,
    partener_6,
    partener_7,
    partener_8,
    partener_9,
    partener_11,
    partener_12,
    partener_13,
    partener_14,
    partener_16,
    partener_17,
    partener_18,
    partener_19,
    partener_20,
    partener_21,
    partener_22,
    partener_23,
    partener_24,
    partener_25,
    partener_26,
    partener_27,
    partener_38,
    partener_39,
    partener_40,
    partener_41,
    partener_42,
    partener_44,
]