import React from 'react';
import './ScrollDown.scss';
import scroll from '../../Assets/scroll.svg';

export default function ScrollDown({click_event=()=>{}}){

    return(
        <div className="scroll" onClick={click_event}>
           <img src={scroll} className="scrollpic" alt="scroll down"></img> 
        </div>
        
    )
}