import React from 'react';
import './Workspage.scss';
import { OneProject } from '../../component';
import gsap from 'gsap';
import {AppDataContext} from '../../App';

var selected_item = 0;
export default function Workspage(){

    var works_ref = React.useRef(null);
    var works_page_ref = React.useRef(null);
    var lineref = React.useRef(null);
    const [selected , setselected]=React.useState(1);
    const {worksdata}=React.useContext(AppDataContext);
    React.useEffect(()=>{
        works_Scroll_add();
        function scrollToselected(){
            let t2 = gsap.timeline({onStart:()=>{setselected(selected_item)} , onComplete:()=>{first=0}});
            t2.to(works_ref.current,{duration:0,y:-(selected_item)*100+"%"});
            t2.fromTo('.OneProject',{y:'100%'},{duration:1,stagger:{from:selected_item-2 , amount:.3},y:0}) 
        }
        scrollToselected();
    },[]);
    var touch_start=0;
    const works_Scroll_add=()=>{
        works_page_ref.current.addEventListener('wheel',works_scroll);
        works_page_ref.current.addEventListener('touchstart',(e)=>{touch_start=e.touches[0].clientY});
        works_page_ref.current.addEventListener('touchend',touch_scroll);
    }
    const scroll_up=()=>{
        if(selected_item>0){
          first++;
          selected_item--;
          let t1 = gsap.timeline({onStart:()=>{setselected(selected_item)} , onComplete:()=>{first=0}});
          t1.set(works_ref.current,{y:-(selected_item)*100+"%"});
          t1.fromTo('.OneProject',{y:'-100%'},{duration:.5,stagger:{from:selected_item-1 , amount:-.3},y:0});
        }
      }
      const scroll_down=()=>{
        if(selected_item<worksdata.length-1){
          first++;
          selected_item++;
          let t2 = gsap.timeline({onStart:()=>{setselected(selected_item)} , onComplete:()=>{first=0;}});
          t2.to(works_ref.current,{duration:0,y:-(selected_item)*100+"%"});
          t2.fromTo('.OneProject',{y:'100%'},{duration:.5,stagger:{from:selected_item-2 , amount:.3},y:0}) 
        }
      }
      var first = 0;
      const works_scroll=(e)=>{
          if(first === 0){
              if(e.deltaY > 0){
               scroll_down();
              }else if(e.deltaY < 0){
               scroll_up();
              }
          }
      }
      const touch_scroll =(e)=>{
        let touch_end = e.changedTouches[0].clientY;
        if(first === 0)
        if(touch_start>touch_end+5){
            scroll_down();
        }else if(touch_start<touch_end-5){
            scroll_up();
        }
        }
    return(
        <div className="Workspage" ref={works_page_ref}>
           <div className="works_lists">
            <div className="works_lists_item" ref={works_ref}>
               {
                worksdata.map((el,index)=>(
                    <OneProject key={index} data={el}></OneProject>
                ))
               }
            </div>
           </div>
           <div className="progress_wrap">
                <div className="progress_number">
                    <span className="current">{selected+1}</span>
                    <span className="length">/{worksdata.length}</span>
                </div>
                <div className="progress_line">
                         <div className="line_place" 
                              ref={lineref}
                              style={{height:100/worksdata.length+'%' , width:'1px' ,transform:'translateY('+100*selected+'%)'}}></div>
                </div>
                <div className="progresse_text">
                 <span>Scroll down</span>
                </div>
           </div>
        </div>
    )
}