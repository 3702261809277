import React from 'react';
import './OneProject.scss'
import gsap from 'gsap';
import AnimatedText from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom';

export default function OneProject({data}){
    var OneP_ref = React.useRef(null);
    const [animate , setanimate]=React.useState(false);
    const calc = (x, y , e) => [ (x - e.target.offsetWidth / 2) / 15 , -(y - e.target.offsetHeight / 2) / 15];
    const trans = (x, y) => `perspective(1600px) rotateX(${x}deg) rotateY(${y}deg)`;
    const oneprojectmove =(e)=>{
         let positionXY = [e.clientX-e.target.getBoundingClientRect().left , e.clientY-e.target.getBoundingClientRect().top]
        gsap.to(OneP_ref.current,{duration:.5,transform:trans(...calc(...positionXY , e))});
    }
    const oneprojectleave =()=>{
        gsap.to(OneP_ref.current,{duration:.6, delay:.1,transform:`perspective(1600px) rotate(0) rotateX(0deg) rotateY(0deg)`});
        setanimate(false);
    }
    const oneprojectover=()=>{
        if(!/Mobi/.test(navigator.userAgent)){
            setanimate(true);
        }
    }
    return(
        <div className="OneProject" style={{transform:'matrix(1, 0, 0, 1, 0, 0)'}}>
        <Link to={{pathname:'/Works/id/'+data.id , state:{fromWorks:0}}} >
           
            <div className="OneProject_content" ref={OneP_ref}>
            <div className="OneProject_pic" 
                 onMouseMove={oneprojectmove} 
                 onMouseOut ={oneprojectleave}
                 onMouseOver={oneprojectover}>
            <img src={data.image} 
                 className="OneProject_pic_image"></img>
            </div>
            <div className="OneProject_text">
                <AnimatedText Text={data.title} animate={animate}></AnimatedText>
             </div>
             </div> 
        </Link>
        </div>
    )
}