import React from 'react';
import './MenuOverlay.scss';
import gsap from 'gsap';
import {OverlayContext} from '../../App';
import { useLocation , useHistory} from 'react-router-dom';
import MenuLink from '../MenuLink/MenuLink';
import CustomEase from 'gsap/CustomEase';

export default function MenuOverlay(){

    var overla_ref=React.useRef(null);
    const {Overlay_open , SetOverlay_open} = React.useContext(OverlayContext);
    const menu_items =['Story','Dimensions','Works','Contacts'];

    var location = useLocation();
    var history = useHistory();
    React.useEffect(()=>{
      if(Overlay_open){
        SetOverlay_open(false);
      }
      },[location.pathname]);
    React.useEffect(()=>{
    gsap.registerPlugin(CustomEase); 
    },[]);
    React.useEffect(()=>{
       if(Overlay_open){
        gsap.fromTo('.sub_letter .letter',{x:-100, opacity:0 , filter:'blur(2px)'},
                   {duration:.5,x:0, filter:'blur(0px)', opacity:1,stagger:{amount:.5,from:"end"} , delay:.5})
       }
      },[Overlay_open]);
    var circleNavRef = React.useRef(null);
    const circle_navigate_move =(e)=>{
      gsap.set(circleNavRef.current,{top:e.clientY,left:e.clientX})
    }
    const circle_navigate_open =(route)=>{
      if("/"+route === location.pathname){
        SetOverlay_open(false);
      }else{
        let t1 = gsap.timeline({paused:true});
        t1.to(circleNavRef.current,{duration:1.5,
                                    height:'300vw',
                                    width:'300vw',
                                    ease:CustomEase.create("custom", "M0,0 C0,0 0.476,-0.016 0.512,0.132 0.518,0.16 0.536,0.24 0.548,0.262 0.606,0.376 0.625,0.387 0.634,0.4 0.754,0.564 0.68,0.456 0.786,0.618 0.857,0.726 0.782,0.618 0.862,0.712 0.874,0.726 0.92,0.733 0.926,0.754 1,1.012 0.92,0.896 1,1 1.062,1.081 1,1 1,1 ")
                                  });
        t1.call(()=>{SetOverlay_open(false)},null,'-=.8');
        t1.to(circleNavRef.current,{duration:.5,opacity:0});
        t1.call(()=>{navigate_to(route)},null,'-=.5');
        t1.set(circleNavRef.current,{width:0,height:0,top:0,left:0,opacity:1});
        t1.play()
      }
    }

    const navigate_to=(route)=>{
       history.push("/"+route);
    }

    return(
      <>
        <div className={Overlay_open ? "MenuOverlay active":"MenuOverlay"}>
          <div className={Overlay_open ? "circle_overlay open":"circle_overlay"} ref={overla_ref}></div> 
          <div className={Overlay_open ? "Menu_items open":"Menu_items"}>
          <ul className="liste_items">
            {
              menu_items.map((item , index)=>(
                <li key={index} onMouseMove={circle_navigate_move} onClick={()=>{circle_navigate_open(item)}}>
                  <MenuLink 
                  text={item} 
                  active={location.pathname==="/"+item}
                  
                  ></MenuLink>
                </li>
              ))
            }
          </ul>
          </div>
        </div>
        <div className="circle_navigation" ref={circleNavRef}></div>
        </>
    )
}