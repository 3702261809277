import React from 'react';
import './Footer.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { OverlayContext, AppDataContext } from '../../App';
import { useLocation } from 'react-router-dom';
export default function Footer(){
    const {Overlay_open} = React.useContext(OverlayContext);
    const [footerV, SetfooterV] = React.useState(true);
    let {sifr_info}=React.useContext(AppDataContext);
    let location =useLocation();
    React.useEffect(()=>{
    if(location.pathname.includes('/Works/')){
        SetfooterV(false);
    }else{
        SetfooterV(true);
    }
    },[location]);

    return(
        
            footerV ? 
                    <div className={Overlay_open  ?"footer black":"footer"}>
                        <div className="Message">
                        <a target="_blank"  rel="noopener noreferrer" href={sifr_info.locationlLink}>
                        {sifr_info.location.map((el,index)=>(
                            <span key={index}> {el}</span>
                        ))}
                        </a>
                        </div>
                        <div className="socialmedia">
                            {
                                sifr_info.socialmedia.map((val,index)=>(
                                    <a href={val.link} key={index} className="icone link" target="_blank"  rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={val.icone}></FontAwesomeIcon>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    :<></>
        
    )
}