import React from 'react';
import './About.scss';
import AboutImage from '../../Assets/about.png';
import AnimatedLink from '../AnimatedLink/AnimatedLink';
import About_slider from '../About_slider/About_slider';
import { AppDataContext } from '../../App';
import { Link } from 'react-router-dom';
export default function About(){
    const {parteners} = React.useContext(AppDataContext);
    return(
        <div className="About">
        <div className="image_about">
        <div className="image_black"></div>
         <img src={AboutImage} className="picture" alt="Sifr background"></img>
         <div className="text_about">
          <span>About us</span>
         </div>
        </div>
        <div className="content_about">
            <div className="content_text">
            We are a group of passionate young Saudis who are aiming to be one of the leaders in creative content creation (CCC) in the middle east. 
            Targeting challenges and hard roads to shape and form our companies’ structure to be able to handle any kind of projects.
            We partner with our clients in shaping their brands and making a positive change that will add value and eventually help them to generate revenues. 
            Our specialty focused on high quality production with no time delivery Photography & videography production aligned with creative creation of graphical visuals & brand building are manifesting our Studio.
            </div>
            <div className="select_work">
            <Link to='/works' className="info">
              <AnimatedLink text="Selected works"></AnimatedLink>
            </Link>
            
            </div>
            <div className="parteners">
            <About_slider images_parteners={parteners} ></About_slider>
            </div>
        </div>
        </div>
    )
}