import React from 'react';
import './Dimensionspage.scss';
import dimension_image from '../../Assets/dimensionspage/Story-02.jpg';
import Mina from '../../Assets/dimensionspage/Mina.png';
import studio from '../../Assets/dimensionspage/Studio.png';
import gsap from 'gsap';
import {DimensionSection, DimensionMain, ScrollDown } from '../../component';
export default function Dimensionspage() {
   const [mobile , Setmobile] = React.useState(false);
   var mediaquerie = window.matchMedia('(max-width:740px)');
   var position_dimension =0;
   const screenTest=(e)=>{
      if(e.matches){
      Setmobile(true);
       gsap.set('.sections',{y:0});
       gsap.set('.section',{opacity:1});
       gsap.set('.scroll',{visibility:'hidden'});
      }else{
       Setmobile(false);
       gsap.set('.scroll',{visibility:'visible'});
      }
   }
    React.useEffect(()=>{
    screenTest(mediaquerie);
    mediaquerie.addListener(screenTest);
    let t1 = gsap.timeline();
    t1.to('.black_back',{duration:2,height:0, ease:'slow'});
    t1.from('.dimension_title',{duration:.5,opacity:0,y:100});
    t1.from('.sifrlogo',{duration:.5,opacity:0,y:100},'-=.1');
    t1.fromTo('.mobile_line',{height:0},{duration:1,height:'calc(100% - 15em)', ease:'slow'},'-=.1');
    t1.to('.line',{duration:1,width:'100%', ease:'slow'},'-=.9');
    t1.from('.logoside',{duration:.5,opacity:0, ease:'slow'},'-=.2');
    t1.from('.content_text',{duration:1,opacity:0,y:100, ease:'slow'},'-=.1');
    t1.set('.scroll_div',{opacity:1});
    return ()=>{
       mediaquerie.removeListener(screenTest);
       t1.kill();
    }
    },[]);
      const add_opacity_0=(index)=>{
         document.getElementsByClassName("section")[index].style.opacity=0;
      }
      const add_opacity_1=(index)=>{
         document.getElementsByClassName("section")[index].style.opacity=1;
      }
      const scroll_up=()=>{
         if(position_dimension>0){
            add_opacity_0(position_dimension);
            first++;
            position_dimension--;
            add_opacity_1(position_dimension);
            gsap.to('.sections',{duration:1.5,y:-position_dimension*100+"%",onComplete:()=>{first=0}});
            gsap.set('.scroll',{visibility:'visible'});
         }
      }
      const scroll_down=()=>{
         if(position_dimension<data.length){
            add_opacity_0(position_dimension);
            first++;
            position_dimension++;
            add_opacity_1(position_dimension);
            gsap.to('.sections',{duration:1.5,y:-position_dimension*100+"%",onComplete:()=>{console.log("complete");first=0}});
            if(position_dimension === data.length){
               gsap.set('.scroll',{visibility:'hidden'});
            }
            }
      }
      var first = 0;
      const scroll_dimensions=(e)=>{
         console.log("how")
          if(first === 0){
              if(e.deltaY > 0){
               scroll_down();
              }else if(e.deltaY < 0){
               scroll_up();
              }
          }
      }
      var touch_start=0;
      const touch_scroll =(e)=>{
         let touch_end = e.changedTouches[0].clientY;
         if(first === 0)
         if(touch_start>touch_end+5){
             scroll_down();
         }else if(touch_start<touch_end-5){
             scroll_up();
         }
      }
    return(
       <>
        <div className="Dimensionspage">
             <img src={dimension_image} alt="Story" className="dimension_background"></img>
             <div className="black_back"></div>
             <div className="sections" 
             onWheel={!mobile || first !=0 ?scroll_dimensions:()=>{}} 
             onTouchStart={!mobile?(e)=>{touch_start=e.touches[0].clientY}:()=>{}}
             onTouchEnd={!mobile?touch_scroll:()=>{}}
             >
             <div className="section">
             <DimensionMain></DimensionMain>
             </div>
             {
               data.map((val,index)=>(
               <div className="section" 
               style={!mobile ?{transform:'translateY('+((index+1)*100)+'%)',opacity:0} :{opacity:1}} 
               key={index}>
                 <DimensionSection data={val}></DimensionSection>
               </div>
                ))
             }
             </div>
        </div>
        <div className="scroll_div" style={{opacity:0}}>
        <ScrollDown click_event={()=>{scroll_down()}}></ScrollDown>
        </div>
        </>
    )
}

const data=[
 {
   logo:studio,
   title_content:"Sifr the studio is where we ride on our own imaginary carpet to fly to the space & the infinite creativity through the Sifr Gateway to express fantasies into realties.",
   content:[
     {
         title:'MARKETING STRATEGY',
         text1:'We do so by understanding our clients’ needs, then utilizing our knowledge of the industry’s in sand outs, add in a touch of madness to the mix, and deliver a carefully crafted message.',
         text2:'Communication Strategies - Campaign Planning Insight and Assessment'
     },
     {
        title:'COMMUNICATION DEVELOPEMNT',
        text1:'We do so by understanding our clients’ needs, then utilizing our knowledge of the industry’s in sand outs, add in a touch of madness to the mix, and deliver a carefully crafted message.',
        text2:'Creative Campaign Concept - Informative/Interactive Content Creative Visuals - Storytelling'
     },
     {
        title:'VISUAL PRODUCTION',
        text1:'We do so by understanding our clients’ needs, then utilizing our knowledge of the industry’s in sand outs, add in a touch of madness to the mix, and deliver a carefully crafted message.',
        text2:'High-end production - Photography - Motion-Graphics Commercial production - Promotional Videos'
     },
     {
        title:'BRAND DEVELOPEMNT',
        text1:'We create contemporary brand identities and digital experiences for a diverse range of premium brands, ranging from boutique start-ups to global businesses.',
        text2:'Visual Systems - Brand Guidelines - Brand Strategy Content Development - Collateral Design'
     },
     {
        title:'CONSULTATION & RESEARCH',
        text1:'Road mapping & appointing the right clock to your watch to maximize the performance and work smartly & effectivly towards your business.',
        text2:'Creative Consultation - Business Consultation - Research Reports & Progress Scouting'
     },
   ],
   colortext2:'#E91A4D'
 },
   {
   logo:Mina,
   title_content:"Eye of change, we combine engineering capabilities with advanced technical solutions along with our creative touch to offer specialized media production. We believe our services are one of a kind in the region to elevate our clients’ outcomes and achievements. We excel in Time-Laps & Aerial production along with a variety of services. Our agility enables a tailoring framework and services based on the market needs following emerging trends.",
   content:[
     {
         title:'TIME-LAPS',
         text1:'Playing with time & space to create special moments with a monitoring online portal illustrating real time view. Oversee your construction, event, and special occasions overtime in creative ways.',
         text2:'Time-Laps - Hyper-Laps - Stop-Motion'
     },
     {
        title:'3D MAPPING',
        text1:'Profiling of objects in three dimensions to map the objects in real world, for architecture & urban planning uses. We use special aerial and laser technologies.',
        text2:'Specialized Drone Scanning - On Ground Laser Scanning Survey Studies - Scouting & AI analysis'
     },
     {
        title:'360 & VR',
        text1:'Be there virtually by capturing moments in a 360 videos & Photos translated into Virtual Reality. We create virtual 360 tours to live an extraordinary experience inside your event, or construction site.',
        text2:'360 Photo - 360 Video - VR tour - VR map'
     },
     {
        title:'AERIAL',
        text1:'Data analysis along with our advanced technology we offer a variety of services including and not limited to Drone Inspections to monitor electricity and mobile towers, Area analysis using AI by gathering real time data to be processed and used in various applications.',
        text2:'Drone Inspections - Area analysis using AI Wide rage scouting and scanning - Thermal Scanning'
     },
     {
        title:'AERIAL IMAGE PROCESS ANALYSIS',
        text1:'Translating all data gathered by our field engineers into useful materials that could help the client to manifest and oversee the work with no time.',
        text2:'Gathering Data - AI Software - Contour Lines Orthomosaic Mapping - Powerful 3D Modeling'
     },
   ],
   colortext2:'#C98D47'
 }
 
];