import React from 'react';
import './About_slider.scss';
import Slider from "react-slick";
export default function About_slider({images_parteners}){
    var settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        speed: 1000,
        swipeToSlide: false,
        lazyLoad: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <></>,
        nextArrow: <></>,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              initialSlide: 2
            }
          }
        ]
      };  
    return(
        <div className="About_slider">
          <Slider {...settings}>
          {
          images_parteners.map((val, index)=>(
              <div key={index}>
                  <div className="partener"  key={index}>
                     <img src={val} className="partener_pic"></img> 
                  </div>
                  
              </div>
                 
             ))  
            } 
          </Slider>
        </div>
    )
}
