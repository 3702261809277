import React from 'react';
import './ContactPage.scss';
import  Map  from '../../Assets/map.jpg';
import { AppDataContext } from '../../App';
import gsap from 'gsap';


export default function ContactPage(){
    React.useEffect(()=>{
        gsap.to('.informations',{opacity:1,duration:.5});
        let T1=gsap.timeline({delay:.5});
        T1.from('.Adress_title',{duration:1.5,x:-100,opacity:0 , ease:"slow"});
        T1.from('.Adress_data',{duration:1.5,x:100,opacity:0, ease:"slow"},'-=1.5');
        let T2=gsap.timeline({delay:.5});
        T2.from('.Message_title',{duration:1.5,x:100,opacity:0, ease:"slow"});
        T2.from('.Message_form input',{duration:1,x:100,opacity:0, ease:"slow",stagger:.3},'-=1.5');
        let T3=gsap.timeline({delay:.5});
        T3.from('.map_container',{duration:1,opacity:0, ease:"slow"});
        T3.from('.text_contact',{duration:1,y:100,opacity:0, ease:"slow"},'-=1');
    },[])
    const {sifr_info} = React.useContext(AppDataContext);
    return(
        <div className="ContactPage">
            <div className="map">
                <div className="map_container">
                    <img src={Map}></img>
                </div>
              <h1 className="text_contact">Contacts</h1>
            </div>
            <div className="informations" style={{opacity:0}}>
                <div className="Adress">
                    <ul className="Adress_list">
                        <li className="Adress_item">
                            <span className="Adress_title">adress</span>
                            <span className="Adress_data">
                            <a  className="Adress_link" target="_blank"  rel="noopener noreferrer" href={sifr_info.locationlLink}> 
                        {sifr_info.location.map((el,index)=>(
                            <span key={index}> {el}</span>
                        ))}</a>
                            </span>
                        </li>
                        <li className="tel_email">
                        <li className="Adress_item tel">
                            <span className="Adress_title">tel</span>
                            <span className="Adress_data">
                                <a  className="Adress_link" href={"tel:"+sifr_info.phonenumber}>{sifr_info.phonenumber}</a>
                            </span>
                        </li>
                        <li className="Adress_item">
                            <span className="Adress_title">e-mail</span>
                            <span className="Adress_data">
                                 <a  className="Adress_link" href={"mailto:"+sifr_info.email}>{sifr_info.email}</a>
                            </span>
                        </li>
                        </li>

                    </ul>
                </div>
                <div className="Message">
                   <h3 className="Message_title">For a project, job or offerings, please do not hesitate to contact us via this form:</h3>
                   <form className="Message_form" id="fs-frm" name="contact-form" acceptCharset="utf-8" action="https://formspree.io/Hello@sifr.sa" method="post">
                       <input placeholder="name" className="field" name="name" required="name is required"></input>
                       <input placeholder="email" className="field" name="_replyto" required="email is required"></input>
                       <input placeholder="subject" className="field" name="message" required="subject is required"></input>
                       {/* <input placeholder="desscription" className="field" name="description" required="description is required"></input> */}
                       <input type="hidden" name="_subject" id="email-subject" value="Contact Form Sifr.sa Submission"></input>
                       <div className="field_div">
                       <input className="field_button" type="submit" value="Send Form"></input>
                       </div>
                   </form>
                </div>                
            </div>
        </div>
    )
}